export default {
    init() {
        // JavaScript to be fired on the home page
    },
    finalize() {
        $('.services__nav__item').hover(function () {
            $(this).addClass('services__nav__item--active');
            $('.services__nav__item').not(this).mouseenter(function () {
                $('.services__nav__item--active').removeClass('services__nav__item--active');
                $(this).addClass('services__nav__item--active');
            });
        });
    },
};
