export const headerPage = {
    init() {
        $(document).ready(function() {
            $('.header__menu__btn').on('click',  function () {
                $('body').toggleClass('header__menu__open')
            })
            $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
            $('.menu-item-touch').on('click', function () {
                $(this).parent('.menu-item').toggleClass('open')
            })

            $(window).scroll(function () {
                let scroll = $(window).scrollTop();
                if (scroll > 50) {
                    $('.header').addClass('header--fix');
                } else {
                    $('.header').removeClass('header--fix');
                }
            });

            $('.header__search__toggle').on('click', function () {
                $('.header__search').toggleClass('open')
                $('.header__search__input input').focus()
            });
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
