export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        // Services fadein effect
        $('.services__nav__item').hover(function () {
            $('.services__content__item').removeClass('services__content__item--show');
            var rel = $(this).attr('rel');
            $('#' + rel).addClass('services__content__item--show');
        });

        //Service section spacing
        function serviceSpacing() {
            $('.services__content').attr('style', 'height:' + $('.services__content__item img').height() + 'px;');
            $('.services__nav').attr('style', 'padding-left:' + $('.header__inner').offset().left + 'px;');
        }
        serviceSpacing();
        setTimeout(function () {
            serviceSpacing();
        }, 1000);

        $(window).resize(function () {
            serviceSpacing();
        });

        // Scroll to section
        $('.scroll-to-section').click(function () {
            var targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top - 60,
                }, 'slow');
            }
        });

        // mobile only goto section
        if ($(window).width() < 576) {
            $('.services__nav__link').click(function () {
                var targetElement = $(this).attr('data-scroll-target');
                if (targetElement) {
                    $('html, body').animate({
                        scrollTop: $('#' + targetElement).offset().top - 200,
                    }, 'slow');
                }
            });
        }

        // Submenu toggle
        if ($(window).width() < 991) {
            $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
            $('.menu-item-touch').on('click', function () {
                $(this).parent('.menu-item').toggleClass('open')
            })
        }

        if ($(window).width() > 991) {
            var $el, leftPos, newWidth;

            /* Add Magic Line markup via JavaScript, because it ain't gonna work without */
            $('#menu-hoofdmenu').append('<li id="slide-line"></li>');

            /* Cache it */
            var $activeLine = $('#slide-line');

            setTimeout(function() {
                $activeLine
                    .width($('.nav .active a').width())
                    .css('left', $('.nav .active').position().left + 30)
                    .data('origLeft', $activeLine.position().left)
                    .data('origWidth', $activeLine.width());
            }, 1000);

            $('.nav li')
                .find('a')
                .hover(
                    function() {
                        $el = $(this);
                        leftPos = $el.parent().position().left + 30;
                        newWidth = $el.width();

                        $activeLine.stop().animate({
                            left: leftPos,
                            width: newWidth,
                        });
                    },
                    function() {
                        $activeLine.stop().animate({
                            left: $activeLine.data('origLeft'),
                            width: $activeLine.data('origWidth'),
                        });
                    }
                );

            $('.sub-menu')
                .children('.menu-item')
                .hover(
                    function() {
                        $el = $(this).parent();
                        leftPos = $el.parent().position().left + 30;
                        newWidth = $el.closest('li').find('a').width();

                        $activeLine.stop().animate({
                            left: leftPos,
                            width: newWidth,
                        });
                    },
                    function() {
                    }
                );

        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
