export const pageTemplateTeam = {
    init() {
        // JavaScript to be fired on all pages
    },
    finalize() {
        /* eslint-disable */
        $(document).ready(function() {
            if ($(window).width() < 768) {
                // Get media - with autoplay disabled (audio or video)
                var media = $('video');
                var tolerancePixel = 40;

                function checkMedia(){
                    // Get current browser top and bottom
                    var scrollTop = $(window).scrollTop() + tolerancePixel;
                    var scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;

                    media.each(function(index, el) {
                        var yTopMedia = $(this).offset().top;
                        var yBottomMedia = $(this).height() + yTopMedia;

                        if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){ //view explaination in `In brief` section above
                            $(this).get(0).play();
                        } else {
                            $(this).get(0).pause();
                        }
                    });

                    //}
                }
                $(document).on('scroll', checkMedia);
            }

        });
        $('.team__item').mouseenter(function () {
            $(this).find('.team__item-text').addClass('show');

            if (!$(this).hasClass('ended')) {
                var el = $(this).children('video')[0];
                el.play();
                el.addEventListener('ended', function() {
                    if(!$(this).hasClass('ended')) {
                        $(this).parent().addClass('ended');
                    }
                });
            }
        });

        $('.team__item').mouseleave(function () {
            $('.team__item-text').removeClass('show');

            var el = $(this).children('video')[0];
            el.pause();
        });

        $('.team__intro__scroll').on('click',function() {
            var offset = 20;
            var target = ".behind";
            if ($(this).data('offset') != undefined) offset = $(this).data('offset');
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset,
            }, 800, 'swing', function() {
                // window.location.hash = target;
            });
        });
        /*eslint eqeqeq:0*/
    },
};

